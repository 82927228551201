<template>
    <b-card>
        <b-row class="mb-2">
            <b-col md="3" class="my-1">
                <b-input-group size="sm" :prepend="$t('show')" :append="$t('records')">
                    <b-form-select v-model="repairs.itemsPerPage" id="perPageSelect" size="sm" :options="repairs.pageOptions"
                                   style="max-width: 5em"></b-form-select>
                </b-input-group>
            </b-col>
			<b-col>
				<b-select v-model="repairs.filters[7].value">
					<b-select-option value="ALL">{{ $t('all') }}</b-select-option>
					<b-select-option value="Invoice">{{ $t('invoiceType') }}</b-select-option>
					<b-select-option value="CreditNote">{{ $t('creditNoteType') }}</b-select-option>
				</b-select>
			</b-col>
            <b-col class="text-right" v-if="user.id > 0 && user.verified && user.status === 'APPROVED'">
                <b-button type="button" variant="primary" class="ml-4" :to="{ name: 'newInvoice' }">
                    <font-awesome-icon icon="plus"></font-awesome-icon> {{ $t('register') }}
                </b-button>
            </b-col>
        </b-row>
        <b-table :items="loadData" :fields="repairs.fields" :filter="repairs.filter" :current-page="repairs.currentPage"
                 :per-page="repairs.itemsPerPage" :empty-text="$t('noRecords')" :empty-filtered-text="$t('noMatches')"
                 striped bordered hover small show-empty :responsive="true" :sort-by="repairs.sortBy" :sort-desc="repairs.sortDesc"
                 ref="repairsTable" class="text-left mb-0" @context-changed="tableContextChanged">
            <template #thead-top>
                <b-tr class="table-filters">
                    <b-th v-for="(field, index) in repairs.fields" :key="field.key"
                          :class="repairs.filters[index] && repairs.filters[index].value && typeof repairs.filters[index].value === 'string' ? 'non-empty' : ''">
                        <b-row v-if="repairs.filters[index]">
                            <b-col v-if="repairs.filters[index].type === 'STRING'">
                                <b-input :placeholder="field.label" v-model="repairs.filters[index].value" debounce="300"></b-input>
                            </b-col>
                            <b-col v-else-if="repairs.filters[index].type === 'Select'">
                                <b-select v-model="repairs.filters[index].value">
                                    <b-select-option value="ALL">{{ $t('all') }}</b-select-option>
                                    <b-select-option value="TO_VALIDATE">{{ $t('toValidate') }}</b-select-option>
                                    <b-select-option value="IN_PROCESS">{{ $t('inProcess') }}</b-select-option>
                                    <b-select-option value="PAID">{{ $t('paid') }}</b-select-option>
                                    <b-select-option value="CANCELLED">{{ $t('cancelled') }}</b-select-option>
                                    <b-select-option value="REJECTED">{{ $t('rejected') }}</b-select-option>
                                    <b-select-option value="APPLIED">{{ $t('applied') }}</b-select-option>
                                </b-select>
                            </b-col>
                        </b-row>
                    </b-th>
                </b-tr>
            </template>

            <template #cell(created)="row">
                <p class="mb-0"><strong>REG:</strong> {{ row.item.created }}</p>
                <p class="mb-0"><strong>FAC:</strong> {{ row.item.invoiced }}</p>
                <p class="mb-0" v-if="row.item.estimatedPaymentDate"><strong>FEP:</strong> {{ row.item.estimatedPaymentDate }}</p>
            </template>

            <template #cell(uuid)="row">
                <template v-if="row.item.requiresXmlFile === '1'">
					<p class="mb-0 d-flex justify-content-between"><span><strong>UUID:</strong> {{ row.item.uuid }} </span><b-badge :variant="row.item.documentType === 'Invoice' ? 'info' : 'warning'" style="width: 20px;" v-b-tooltip.hover.top :title="row.item.documentType === 'Invoice' ? $t('invoiceType') : $t('creditNoteType')">{{ row.item.documentType === 'Invoice' ? $t('documentTypeInvoice') : $t('documentTypeCreditNote') }}</b-badge></p>
                    <p class="mb-0"><strong>{{ $t('serial') }}:</strong> {{ row.item.serial }}</p>
                    <p class="mb-0"><strong>{{ $t('folio') }}:</strong> {{ row.item.folio }}</p>
                </template>
                <template v-else>
                    <p class="mb-0 d-flex justify-content-between"><span><strong>{{ $t('invoiceNumber') }}:</strong> {{ row.item.invoiceNumber }}</span><b-badge :variant="row.item.documentType === 'Invoice' ? 'info' : 'warning'" style="width: 20px;" v-b-tooltip.hover.top :title="row.item.documentType === 'Invoice' ? $t('invoiceType') : $t('creditNoteType')">{{ row.item.documentType === 'Invoice' ? $t('documentTypeInvoice') : $t('documentTypeCreditNote') }}</b-badge></p>
                </template>
            </template>

            <template #cell(total)="row">
                $ {{ formatMoney(row.item.total, 2, [',',',','.'])  }}
            </template>

            <template #cell(status)="row">
                <b-badge variant="warning" class="p-1" v-if="row.item.status === 'TO_VALIDATE'">{{ $t('toValidate') }}</b-badge>
                <b-badge variant="info" class="p-1" v-if="row.item.status === 'IN_PROCESS'">{{ $t('inProcess') }}</b-badge>
                <b-badge variant="success" class="p-1" v-if="row.item.status === 'PAID'">{{ $t('paid') }}</b-badge>
                <b-badge variant="danger" class="p-1" v-if="row.item.status === 'CANCELLED'">{{ $t('cancelled') }}</b-badge>
                <b-badge variant="danger" class="p-1" v-if="row.item.status === 'REJECTED'">{{ $t('rejected') }}</b-badge>
                <b-badge variant="success" class="p-1" v-if="row.item.status === 'APPLIED'">{{ $t('applied') }}</b-badge>
            </template>

            <template #cell(actions)="row">
                <b-button-group size="sm">
                    <b-button size="sm" v-if="row" v-b-tooltip.hover
                              :title="$t('detail')" variant="outline-primary"
                              @click="showInvoice(row.item.id)">
                        <font-awesome-icon icon="search"></font-awesome-icon>
                    </b-button>
                </b-button-group>
            </template>

            <template #table-caption>
                <b-row class="no-gutters">
                    <b-col sm="5" md="6">
                        {{ $t('recordDisplayed').replace('START', start).replace('END', end).replace('TOTAL_ROWS', repairs.totalRows) }}<template v-if="repairs.totalRows !== 1">s</template>.
                    </b-col>
                    <b-col sm="7" md="6" class="my-1">
                        <b-pagination v-model="repairs.currentPage" :total-rows="repairs.totalRows"
                                      :per-page="repairs.itemsPerPage" align="right" class="mb-0"></b-pagination>
                    </b-col>
                </b-row>
            </template>
        </b-table>
    </b-card>
</template>

<script>
import tableStateMixin from '../../mixins/tableState';
import { invoiceListUrl } from '@routes';
import * as constants from '@constants';
import { formatMoney } from '@/js/utilities';

export default {
    mixins: [tableStateMixin],
    data() {
        return {
            repairs: {
                fields: [
                    { key: 'created', label: this.$t('dates'), sortable: true },
                    { key: 'uuid', label: 'UUID', sortable: true },
                    { key: 'purchaseOrder', label: this.$t('purchaseOrder'), sortable: true },
                    { key: 'total', label: this.$t('amount'), sortable: true, class: 'text-right' },
                    { key: 'currency', label: this.$t('currency'), sortable: true, class: 'text-center' },
                    { key: 'status', label: this.$t('status'), sortable: true, class: 'text-center' },
                    { key: 'actions', label: this.$t('actions'), class: 'text-center' }
                ],
                filters: [
                    { column: 'created', type: 'STRING', value: '' },
                    { column: 'uuid', type: 'STRING', value: '' },
                    { column: 'purchaseOrder', type: 'STRING', value: '' },
                    { column: 'total', type: 'STRING', value: '' },
                    { column: 'currency', type: 'STRING', value: '' },
                    { column: 'status', type: 'Select', value: 'ALL' },
                    { column: '', type: 'NA', value: null },
                    { column: 'documentType', type: 'Select', value: 'ALL' }
                ],
                filter: '',
                isLoading: false,
                pageOptions: [25, 50, 75, 100],
                totalRows: 0,
                currentPage: 1,
                itemsPerPage: 25,
                sortBy: 'b.name',
                sortDesc: false
            },
            breadcrumb: {
                title: this.$t('invoices'),
                path: []
            },
            user: {
                id: 0,
                name: 'User Name',
                email: '',
                username: '',
                roles: '',
                verified: false,
                status: '',
                supplierId: 0,
                supplierLegalName: '',
                reason: ''
            }
        };
    },
    mounted() {
        this.user = JSON.parse(localStorage.getItem('user'));
        this.$emit('update-breadcrumb', this.breadcrumb);
        localStorage.removeItem('invoiceId');
        this.restoreTableState(this.repairs, this.tableState);
    },
    methods: {
        formatMoney: formatMoney,
        loadData(ctx) {
            if (ctx.sortBy !== '') {
                let data = {
                    filters: this.repairs.filters.filter(filter => filter.value && typeof filter.value === 'string' && filter.value.trim() !== ''),
                    ...ctx
                };

                data.currentPage = (data.currentPage - 1) * data.perPage;

                return this.axios.post(invoiceListUrl(this.user.supplierId), data).then(response => {
                    if (response.data.code === constants.CODE_OK) {
                        this.repairs.totalRows = response.data.totalRows;
                        return response.data.invoices;
                    } else {
                        return [];
                    }
                }).catch(error => {
                    console.error(error);

                    return [];
                });
            }
        },
        tableContextChanged(context) {
            this.repairs.sortBy = context.sortBy;
            this.repairs.sortDesc = context.sortDesc;

            this.saveTableState(this.$route.name, this.repairs);
        },
        showInvoice(invoiceId) {
            localStorage.setItem('invoiceId', invoiceId);
            this.$router.push({ name: 'invoiceDetail' });
        }
    },
    watch: {
        'repairs.filters': {
            handler() {
                this.$refs.repairsTable.refresh();
                this.saveTableState(this.$route.name, this.repairs);
            },
            deep: true
        }
    },
    computed: {
        start: function() {
            return this.repairs.totalRows === 0 ? 0 : (this.repairs.currentPage - 1) * this.repairs.itemsPerPage + 1;
        },
        end: function() {
            let offset = (this.repairs.currentPage - 1) * this.repairs.itemsPerPage;

            return (this.repairs.totalRows - offset) < this.repairs.itemsPerPage ? this.repairs.totalRows : offset + this.repairs.itemsPerPage;
        }
    }
};
</script>